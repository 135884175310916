<template>
  <products-table />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import ProductsTable from '../components/products/ProductsTable.vue'
  export default {
    name: 'AdminProducts',
    components: {
      ProductsTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness']),
    },

    async created () {
      await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      await this.fetchProductLine({ id: this.user.businessId || this.actionsParentBusiness })
      await this.fetchProductClassification({ id: this.user.businessId || this.actionsParentBusiness })
      await this.fetchProductCategory({ id: this.user.businessId || this.actionsParentBusiness })
      await this.fetchProductType({ id: this.user.businessId || this.actionsParentBusiness })
    },

    methods: {
      ...mapActions([
        'fetchProductLine',
        'fetchProductClassification',
        'fetchProductCategory',
        'fetchProductType',
        'fetchProducts',
      ]),
    },
  }
</script>
