<template>
  <v-form
    v-model="isValidForm"
    @submit="handleSave"
  >
    <v-card-text>
      <v-sheet elevation="0">
        <v-row class="mx-4">
          <!-- <v-col cols="12">
            <v-text-field
              v-model="data.name"
              type="text"
              :rules="requiredFieldRules('codigo es requerido')"
              outlined
              label="Codigo"
              required
            />
          </v-col> -->
          <!-- <pre>{{ JSON.stringify(data, null, 2) }}</pre> -->
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="data.codigo"
              type="text"
              :rules="requiredFieldRules('codigo es requerido')"
              outlined
              label="Codigo"
              required
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="data.salesUnit"
              label="Unidad"
              :items="salesUnits"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="data.packingUnit"
              label="Unidad de empaque"
              :items="packingUnits"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="data.description"
              type="text"
              :rules="requiredFieldRules('Nombre es requerido')"
              outlined
              label="Nombre"
              required
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="data.productLinesId"
              label="Linea de producto"
              :items="productLines"
              :loading="fetchingProductLine"
              :disabled="fetchingProductLine"
              item-text="description"
              item-value="id"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="data.productClassificationsId"
              label="Clasificacion de producto"
              :items="productClassifications"
              :loading="fetchingProductClassification"
              :disabled="fetchingProductClassification"
              item-text="description"
              item-value="id"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="data.productCategoriesId"
              label="Categoria de producto"
              :items="productCategorys"
              :loading="fetchingProductCategory"
              :disabled="fetchingProductCategory"
              item-text="description"
              item-value="id"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="data.productTypesId"
              label="Tipo de producto"
              :items="productTypes"
              :loading="fetchingProductType"
              :disabled="fetchingProductType"
              item-text="description"
              item-value="id"
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="data.bigWholesalePrices"
              type="number"
              min="1"
              :rules="requiredFieldRules('Precio es requerido')"
              outlined
              label="Precio Gran mayor"
              required
              prepend-inner-icon="mdi-currency-usd"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="data.wholesalePrices"
              type="number"
              min="1"
              :rules="requiredFieldRules('Precio es requerido')"
              outlined
              label="Precio al Mayor"
              required
              prepend-inner-icon="mdi-currency-usd"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="data.retailPrices"
              type="number"
              min="1"
              :rules="requiredFieldRules('Precio es requerido')"
              outlined
              label="Precio al detal"
              required
              prepend-inner-icon="mdi-currency-usd"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="data.tax"
              type="number"
              min="0"
              :rules="requiredFieldRules('IVA es requerido')"
              outlined
              label="Porcentaje de IVA"
              required
              prepend-inner-icon="mdi-percent-outline"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        color="red"
        type="button"
        @click="$emit('close')"
      >
        Cerrar
      </v-btn>
      <v-btn
        text
        color="green"
        type="submit"
        :disabled="disabledSave"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'EditProductsForm',
    props: {
      product: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        isValidForm: false,
        packingUnits: ['ROLLOS', 'CAJAS'],
        salesUnits: ['KG', 'UNIDAD', 'M', 'LB'],
        data: {
          codigo: '',
          description: '',
          salesUnit: 'KG',
          packingUnit: 'ROLLOS',
          wholesalePrices: 1,
          bigWholesalePrices: 1,
          retailPrices: 1,
          tax: 0,
          productLinesId: null,
          productClassificationsId: null,
          productCategoriesId: null,
          productTypesId: null,
        },
      }
    },

    computed: {
      ...mapState([
        'user',
        'actionsParentBusiness',
        'fetchingProductLine',
        'fetchingProductClassification',
        'fetchingProductCategory',
        'fetchingProductType',
        'fetchingProducts',
        'productLine',
        'productClassification',
        'productCategory',
        'productType',
      ]),
      productLines () {
        return this.productLine.filter(item => item.status)
      },
      productClassifications () {
        return this.productClassification.filter(item => item.status)
      },
      productCategorys () {
        return this.productCategory.filter(item => item.status)
      },
      productTypes () {
        return this.productType.filter(item => item.status)
      },
      businessId () {
        return (this.user.businessId || this.actionsParentBusiness) || null
      },
      disabledSave () {
        let res = false
        for (const [key] of Object.entries(this.data)) {
          res = this.data[key] === false || this.data[key] === null
        }
        return res
      },
    },

    watch: {
      data (v) {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('DATA', v)
      },
    },

    mounted () {
      // // // // // // // // // // // // // // // // // // // // // // // // console.log('pro', this.product)
      this.data = { ...this.product }
      // this.data.name = this.products.description
      // this.data.id = this.products.id
      // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('ware', this.warehouse)
      // this.data = this.warehouse
    },

    methods: {
      ...mapActions([
        'updateProducts',
        'fetchProducts',
      ]),
      async handleSave (e) {
        e.preventDefault()
        // // // // // // // // // // // // // // // // // // // // // // // console.log('clit', this.isValidForm)
        // if (this.isValidForm && !this.disabledSave) {
        if (!this.disabledSave) {
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('datita', this.data)
          await this.updateProducts({
            ...this.data,
            businessId: this.user.businessId || this.actionsParentBusiness,
          })
          this.$emit('close')
          await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
        }
      },
      requiredFieldRules (errorMessage) {
        return [
          v => !!v || errorMessage,
        ]
      },
    },
  }
</script>
