<template>
  <v-form
    v-model="isValidData"
    @submit="submit"
  >
    <v-card-text>
      <v-container>
        <!-- {{ disabledSave }} -->
        <v-sheet elevation="0">
          <v-row class="mx-4">
            <!-- <v-col cols="12">
              <v-text-field
                v-model="data.name"
                type="text"
                :rules="requiredFieldRules('codigo es requerido')"
                outlined
                label="Codigo"
                required
              />
            </v-col> -->
            <!-- <pre>{{ JSON.stringify(data, null, 2) }}</pre> -->
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="data.codigo"
                type="text"
                :rules="requiredFieldRules('codigo es requerido')"
                outlined
                label="Codigo"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="data.salesUnit"
                label="Unidad"
                :items="salesUnits"
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="data.packingUnit"
                label="Unidad de empaque"
                :items="packingUnits"
                outlined
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="data.description"
                type="text"
                :rules="requiredFieldRules('Nombre es requerido')"
                outlined
                label="Nombre"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="data.productLinesId"
                label="Linea de producto"
                :items="productLines"
                :loading="fetchingProductLine"
                :disabled="fetchingProductLine"
                item-text="description"
                item-value="id"
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="data.productClassificationsId"
                label="Clasificacion de producto"
                :items="productClassifications"
                :loading="fetchingProductClassification"
                :disabled="fetchingProductClassification"
                item-text="description"
                item-value="id"
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="data.productCategoriesId"
                label="Categoria de producto"
                :items="productCategorys"
                :loading="fetchingProductCategory"
                :disabled="fetchingProductCategory"
                item-text="description"
                item-value="id"
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="data.productTypesId"
                label="Tipo de producto"
                :items="productTypes"
                :loading="fetchingProductType"
                :disabled="fetchingProductType"
                item-text="description"
                item-value="id"
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="data.bigWholesalePrices"
                type="number"
                min="1"
                :rules="requiredFieldRules('Precio es requerido')"
                outlined
                label="Precio Gran mayor"
                required
                prepend-inner-icon="mdi-currency-usd"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="data.wholesalePrices"
                type="number"
                min="1"
                :rules="requiredFieldRules('Precio es requerido')"
                outlined
                label="Precio al Mayor"
                required
                prepend-inner-icon="mdi-currency-usd"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="data.retailPrices"
                type="number"
                min="1"
                :rules="requiredFieldRules('Precio es requerido')"
                outlined
                label="Precio al detal"
                required
                prepend-inner-icon="mdi-currency-usd"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="data.tax"
                type="number"
                min="0"
                :rules="requiredFieldRules('IVA es requerido')"
                outlined
                label="Porcentaje de IVA"
                required
                prepend-inner-icon="mdi-percent-outline"
              />
            </v-col>
          </v-row>
        </v-sheet>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="red"
        text
        type="button"
        :disabled="fetchingProducts"
        @click="$emit('close')"
      >
        Cerrar
      </v-btn>
      <v-btn
        type="submit"
        color="success"
        text
        :disabled="disabledSave"
        :loading="fetchingProducts"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'AddProductTypeForm',
    data () {
      return {
        isValidData: false,
        step: 1,
        salesUnits: ['KG', 'UNIDAD', 'M', 'LB'],
        packingUnits: ['ROLLOS', 'CAJAS'],
        data: {
          codigo: '',
          description: '',
          salesUnit: 'KG',
          packingUnit: 'ROLLOS',
          wholesalePrices: 1,
          bigWholesalePrices: 1,
          retailPrices: 1,
          tax: 15,
          productLinesId: null,
          productClassificationsId: null,
          productCategoriesId: null,
          productTypesId: null,
        },
      }
    },
    computed: {
      ...mapState([
        'user',
        'actionsParentBusiness',
        'fetchingProductLine',
        'fetchingProductClassification',
        'fetchingProductCategory',
        'fetchingProductType',
        'fetchingProducts',
        'productLine',
        'productClassification',
        'productCategory',
        'productType',
      ]),
      productLines () {
        return this.productLine.filter(item => item.status)
      },
      productClassifications () {
        return this.productClassification.filter(item => item.status)
      },
      productCategorys () {
        return this.productCategory.filter(item => item.status)
      },
      productTypes () {
        return this.productType.filter(item => item.status)
      },
      businessId () {
        return (this.user.businessId || this.actionsParentBusiness) || null
      },
      disabledSave () {
        let res = false
        for (const [key] of Object.entries(this.data)) {
          res = this.data[key] === false || this.data[key] === null
        }
        return res
      },
    },

    methods: {
      ...mapActions([
        'fetchProducts',
        'createProducts',
      ]),
      requiredFieldRules (text) {
        return [
          v => !!v || text,
        ]
      },
      resetData () {
        for (const [key] of Object.entries(this.data)) {
          this.data[key] = '' // Making all keys of the object ''
        }
      },
      async afterCreate () {
        this.$emit('close')
        this.resetData()
        await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      },
      async submit (e) {
        e.preventDefault()
        const businessId = this.businessId
        if (this.isValidData && this.businessId !== null && this.disabledSave === false) {
          const submit = {
            ...this.data,
            businessId,
          }
          // // // // // // // // // // // // // // // // // // // // // // // // console.log('sub', submit)
          await this.createProducts(submit)
          this.afterCreate()
        }
      },
    },
  }
</script>
