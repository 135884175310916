<template>
  <v-container
    fluid
    tag="section"
  >
    <!-- <pre> {{ products }} </pre> -->
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          icon="mdi-basket"
          title="Productos"
          class="px-5 py-3"
          color="orange"
        >
          <v-data-table
            :headers="headers"
            :items="products"
            :loading="fetchingProducts"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:top>
              <products-table-top />
            </template>
            <template v-slot:[`item.retailPrices`]="{ item }">
              <span>{{ formatPrice(item.retailPrices) }}</span>
            </template>
            <template v-slot:[`item.wholesalePrices`]="{ item }">
              <span>{{ formatPrice(item.wholesalePrices) }}</span>
            </template>
            <template v-slot:[`item.bigWholesalePrices`]="{ item }">
              <span>{{ formatPrice(item.bigWholesalePrices) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item: product }">
              <products-actions
                :product="product"
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import ProductsTableTop from './ProductsTableTop'
  import ProductsActions from './ProductsActions.vue'

  export default {
    name: 'ProductsTable',
    components: {
      ProductsTableTop,
      ProductsActions,
    },
    data () {
      return {
        headers: [
          {
            text: 'Codigo',
            value: 'codigo',
          },
          {
            text: 'Nombre',
            value: 'description',
          },
          {
            text: 'Linea',
            value: 'productLinesDescription',
          },
          {
            text: 'Clasificacion',
            value: 'productClassificationsDescription',
          },
          {
            text: 'Categoria',
            value: 'productCategoriesDescription',
          },
          {
            text: 'Tipo',
            value: 'productTypesDescription',
          },
          {
            text: 'Precio',
            value: 'retailPrices',
          },
          {
            text: 'Precio al mayor',
            value: 'wholesalePrices',
          },
          {
            text: 'Precio Gran mayor',
            value: 'bigWholesalePrices',
          },
          {
            text: 'Unidad',
            value: 'salesUnit',
          },
          {
            text: 'Unidad de empaque',
            value: 'packingUnit',
          },
          {
            text: 'Acciones',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ],
      }
    },
    computed: {
      ...mapState([
        'products',
        'fetchingProducts',
      ]),
    },
    methods: {
      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat(
          'en-ve',
          { style: 'currency', currency: type, minimumFractionDigits: 2 },
        ).format(v)
      },
    },
  }
</script>
