var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-basket","title":"Productos","color":"orange"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"loading":_vm.fetchingProducts,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
            'items-per-page-text': 'Filas por página',
            pageText: '{0}-{1} de {2}'
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('products-table-top')]},proxy:true},{key:"item.retailPrices",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPrice(item.retailPrices)))])]}},{key:"item.wholesalePrices",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPrice(item.wholesalePrices)))])]}},{key:"item.bigWholesalePrices",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPrice(item.bigWholesalePrices)))])]}},{key:"item.actions",fn:function(ref){
          var product = ref.item;
return [_c('products-actions',{attrs:{"product":product}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }